<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-form @submit="confirm = true">
        <q-card-section align="right">
          <a class="text-grey text-h6">IDENTITAS</a>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section class="q-gutter-md q-mb-xl">
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">Jenjang</a>
            <q-select
              dense
              :options="$LIST_JENJANG"
              v-model="selJenjang"
              outlined
              readonly
              class="col-6"
            ></q-select>
          </div>
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">Nama</a>
            <q-input
              dense
              @change="isChange = true"
              outlined
              v-model="musyrifDetail.nama"
              class="col-6"
              lazy-rules
              :rules="[
                (val) => (val && val.length > 0) || 'Nama Tidak Boleh Kosong',
              ]"
            ></q-input>
          </div>
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">Jenis Kelamin</a>
            <div class="q-gutter-sm col-6">
              <q-radio
                class="q-ml-none"
                v-model="musyrifDetail.gender"
                label="L"
                val="L"
              ></q-radio>
              <q-radio
                v-model="musyrifDetail.gender"
                label="P"
                val="P"
              ></q-radio>
            </div>
          </div>
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">NIK</a>
            <q-input
              dense
              @change="isChange = true"
              outlined
              v-model="musyrifDetail.nik"
              class="col-6"
            ></q-input>
          </div>
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">NIGN</a>
            <q-input
              dense
              @change="isChange = true"
              outlined
              v-model="musyrifDetail.nign"
              class="col-6"
            ></q-input>
          </div>
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">No.HP</a>
            <q-input
              dense
              @change="isChange = true"
              outlined
              v-model="musyrifDetail.hp"
              class="col-6"
            ></q-input>
          </div>
          <div class="row items-center justify-between">
            <a class="item col-6 text-left">E-mail</a>
            <q-input
              dense
              @change="isChange = true"
              outlined
              v-model="musyrifDetail.email"
              class="col-6"
            ></q-input>
          </div>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section align="right">
          <a class="text-grey text-h6">JABATAN</a>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section class="column q-gutter-md q-mb-xl">
          <q-checkbox
            @input="isChange = true"
            v-model="musyrifDetail.is_musyrif"
            label="Musyrif/ah"
          />
          <q-checkbox
            @input="isChange = true"
            v-model="musyrifDetail.is_murabbi_tahfidz"
            label="Murabbi Tahfidz"
          />
          <q-checkbox
            @input="isChange = true"
            v-model="musyrifDetail.is_murabbi_mentor"
            label="Murabbi Mentor"
          />
          <q-checkbox
            @input="isChange = true"
            v-model="musyrifDetail.is_waka"
            label="Waka Pengasuhan"
          />
          <q-checkbox
            @input="isChange = true"
            v-model="musyrifDetail.is_kepala_pengasuhan"
            label="Kepala Pengasuhan"
          />
          <q-checkbox
            @input="isChange = true"
            v-model="musyrifDetail.is_super_user"
            label="Super Admin"
          />
        </q-card-section>
        <q-separator></q-separator>
        <q-card-actions align="between">
          <q-btn
            flat
            color="primary"
            label="back"
            @click="$router.go(-1)"
          ></q-btn>
          <q-btn
            outline
            label="Reset Password"
            color="primary"
            @click="alert = true"
            v-if="$route.params.id > 0"
          ></q-btn>
          <q-btn
            unelevated
            color="primary"
            label="simpan"
            type="submit"
          ></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
    <q-dialog v-model="confirm">
      <q-card>
        <q-card-section>Apa anda yakin ingin menyimpan data?</q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="close" color="primary" v-close-popup></q-btn>
          <q-btn
            unelevated
            v-if="$route.params.id < 0"
            label="Ya"
            color="primary"
            @click="tambahMusyrif"
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            v-else
            label="Ya"
            color="primary"
            @click="updateMusyrif"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-if="musyrifDetail.nik" v-model="alert" persistent>
      <q-card>
        <q-card-section>
          Tulis "{{ musyrifDetail.nik }}" tanpa tanda kutip untuk me-reset
          password
          {{ musyrifDetail.nama }}
          <q-input outlined dense v-model="keyword"></q-input>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="close" v-close-popup></q-btn>
          <q-btn
            unelevated
            label="Reset"
            :disable="keyword.toLowerCase() != musyrifDetail.nik.toLowerCase()"
            color="primary"
            @click="resetpwd"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alert: false,
      keyword: "",

      confirm: false,

      isChange: false,
      musyrifDetail: {
        gender: "L",
        is_musyrif: true,
        is_murabbi_tahfidz: false,
        is_murabbi_mentor: false,
        is_waka: false,
        is_kepala_pengasuhan: false,
        is_super_user: false,
      },
      selJenjang: "",
    };
  },
  mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    this.selJenjang = this.$route.params.selJenjang;
    if (this.$route.params.id > 0) this.getMusyrifDetail();
    this.$q.loading.hide();
  },
  methods: {
    async resetpwd() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let data = {
        id: this.musyrifDetail.id,
      };
      var md5 = require("md5");
      data.pass = md5(this.musyrifDetail.nik);
      await this.$http.put(`/pengasuhan/musyrif/resetpwd`, data);
      this.$q.notify({
        message: `Password ${this.musyrifDetail.nama} berhasil di reset`,
        color: "positive",
      });
      await this.updateMusyrif();
      this.$q.loading.hide();
      this.$router.push("/pengasuhanlistmusyrif/" + this.selJenjang);
    },
    async getMusyrifDetail() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          "/pengasuhan/musyrif/detail/" +
            this.$route.params.id +
            "/" +
            this.$route.params.selJenjang,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.musyrifDetail = resp.data;
          this.checkfilter();
        });
      this.$q.loading.hide();
    },
    checkfilter() {
      if (this.musyrifDetail.is_musyrif == 1)
        this.musyrifDetail.is_musyrif = true;
      else this.musyrifDetail.is_musyrif = false;

      if (this.musyrifDetail.is_murabbi_tahfidz == 1)
        this.musyrifDetail.is_murabbi_tahfidz = true;
      else this.musyrifDetail.is_murabbi_tahfidz = false;

      if (this.musyrifDetail.is_murabbi_mentor == 1)
        this.musyrifDetail.is_murabbi_mentor = true;
      else this.musyrifDetail.is_murabbi_mentor = false;

      if (this.musyrifDetail.is_waka == 1) this.musyrifDetail.is_waka = true;
      else this.musyrifDetail.is_waka = false;

      if (this.musyrifDetail.is_kepala_pengasuhan == 1)
        this.musyrifDetail.is_kepala_pengasuhan = true;
      else this.musyrifDetail.is_kepala_pengasuhan = false;

      if (this.musyrifDetail.is_super_user == 1)
        this.musyrifDetail.is_super_user = true;
      else this.musyrifDetail.is_super_user = false;
    },
    async updateMusyrif() {
      this.$q.loading.show({ message: "Menyimpan Perubahan" });
      let acc = true;
      try {
        await this.$http.put(
          `/pengasuhan/musyrif/edit/${this.selJenjang}/${this.$route.params.id}`,
          this.musyrifDetail,
          this.$hlp.getHeader()
        );
      } catch (err) {
        this.$q.notify({
          message: `Nama yang anda masukan Sudah Terdaftar pada ${this.selJenjang}, Silahkan mengganti atau menambahkan karakter unik pada nama`,
          color: "primary",
          timeout: 8000,
        });
        acc = false;
      }

      this.$q.loading.hide();
      if (acc) {
        this.$router.push("/pengasuhanlistmusyrif/" + this.selJenjang);
      }
    },
    async tambahMusyrif() {
      this.$q.loading.show({ message: "Menyimpan Data" });
      let acc = true;
      try {
        var md5 = require("md5");
        this.musyrifDetail.password = md5(this.musyrifDetail.nik);
        await this.$http.post(
          `/pengasuhan/musyrif/tambah/${this.selJenjang}`,
          this.musyrifDetail,
          this.$hlp.getHeader()
        );
      } catch (err) {
        this.$q.notify({
          message: `Nama yang anda masukan Sudah Terdaftar pada ${this.selJenjang}, Silahkan mengganti atau menambahkan karakter unik pada nama`,
          color: "primary",
          timeout: 8000,
        });
        acc = false;
      }

      this.$q.loading.hide();
      if (acc) {
        this.$router.push("/pengasuhanlistmusyrif/" + this.selJenjang);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
